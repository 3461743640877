<template>
  <div class="container h-100" v-if="!isLoading">
    <div class="h-100 position-relative">
        <a class="rw-header-link" href="javascript:void(0)" @click="backToPreviousPage">
          <svg fill="#fff" width="33px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.78 19.03a.75.75 0 01-1.06 0l-6.25-6.25a.75.75 0 010-1.06l6.25-6.25a.75.75 0 111.06 1.06L5.81 11.5h14.44a.75.75 0 010 1.5H5.81l4.97 4.97a.75.75 0 010 1.06z"/></svg>
        </a>
        <div class="rw-header-card">
          <h2 class="rw-header-title" :style="headerTitleStyle">{{header_title}}</h2>
        </div>
        <div class="rw-beta-tag">
          Beta
        </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";

export default {
  name: "Header",
  components: {
    DrawerLayout
  },
  data: () => ({
    open: true,
    showmenuicon: true,
    spin_available_count: null,
    rewardBackButtonState: true,
    header_title: "",
    headerTitleStyle: "",
    isLoading: true
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });

    this.$root.$on("spinAvailableCount", function (data) {
      self.spin_available_count = data.spin_available_count;
    });

    this.$root.$on("rewardBackButtonState", function (data) {
        self.rewardBackButtonState = data.rewardBackButtonState;
    });

    self.$root.$on("rewardWheelHeaderTitle", function (data) {
      self.header_title = data.title
      self.headerTitleStyle = 'font-size:' + data.size + 'px!important;'
    });

    self.$root.$on("rewardWheelLoader", function (data) {
      self.isLoading = data.loading
    });
  },
  destroyed(){
    this.$root.$emit("removeWheelDesignValues");
    this.$root.$emit("rewardWheelPageColor", {
        image: "",
        is_image: false,
        color_1: "",
        color_2: "",
        color_3: ""
    });
  },
  methods: {
    backToPreviousPage() {
      if(this.$online){
        if(this.rewardBackButtonState){
          if(this.$route.query && this.$route.query.wheel_id){
            this.$router.push('/pay')
          }else{
            this.$router.push('/pay')
          }
        }
      }
    }
  }
};
</script>

<style>
.rw-header-link{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.rw-header-card{
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.rw-header-title{
  color: #fff;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
}
.rw-header-text{
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.rw-beta-tag{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #0e7432;
  color: #fff;
  padding: 5px 13px;
  border-radius: 50px;
  font-size: 15px;
}

.rw-beta-tag.dark{
  background: #095a26;
  padding: 4px 12px;
  font-size: 14px;
  margin-top:-10px;
}

.rw-merchant-change-text{
  position: absolute !important;
  right: 0 !important;
}


@media only screen and ( min-width:280px) and ( max-width:700px) {
  .rw-header-link{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rw-header-link svg{
    width: 28px;
  }
  .rw-header-card{
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .rw-header-title{
    color: #fff;
    font-size: 25px;
    font-weight: bold;
  }
  .rw-header-text{
    color: #fff;
    font-size: 11px;
    font-weight: 600;
  }
  .reward-wheel-nav{
    height: 88px;
  }
  .rw-beta-tag{
    padding: 3px 10px;
    font-size: 12px;
  }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
  .rw-header-link{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rw-header-link svg{
    width: 28px;
  }
  .rw-header-card{
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .rw-header-title{
    color: #fff;
    font-size: 27px;
    font-weight: bold;
  }
  .rw-header-text{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
  .rw-beta-tag{
    padding: 3px 10px;
    font-size: 13px;
  }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
  .rw-header-link{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .rw-header-link svg{
    width: 33px;
  }
  .rw-header-card{
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .rw-header-title{
    color: #fff;
    font-size: 34px;
    font-weight: bold;
  }
  .rw-header-text{
    color: #fff;
    font-size: 15px;
    font-weight: 600;
  }
  .rw-beta-tag{
    padding: 5px 13px;
    font-size: 15px;
  }
}
</style>
